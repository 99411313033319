import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row,Button } from 'react-bootstrap'
import FormField from '../../components/layout/FormField'
import SearchField from '../../components/layout/SearchField'
import CustomTable, { AddButton, DeleteButton, EditButton, ViewButton } from '../../components/layout/CustomTable'
import { MotionDiv } from '../../components'
import QuestionModal from '../../components/modals/QuestionModal'
import { useNavigate, useParams } from 'react-router-dom'
import ViewTestModal from '../../components/modals/ViewTestModal'
import { FaRegSave } from 'react-icons/fa'
import ModalTemplate from '../../components/modals/ModalTemplate'
import { useCreateTestMutation, useGetDomainsMutation, useGetQuestionsMutation, useGetSubTopicsMutation, useGetSubdomainsMutation, useGetTestByIdMutation,useUpdateTestMutation, useGetTopicsMutation } from '../../features/apiSlice'
import { fetchDomains, fetchQuestions, fetchSubdomains, fetchSubtopics, fetchTopics } from '../../utils/apis'
import { getError } from '../../utils/error'
import { toast } from 'react-toastify'
import CreateMarkup from '../../components/layout/CreateMarkup'


function CreateTest() {

  const [getTopics,{isLoading:topicLoading}] = useGetTopicsMutation();
  const [getSubtopics,{isLoading:subtopicLoading}] = useGetSubTopicsMutation();
  const [getDomains,{isLoading:domainLoading}] = useGetDomainsMutation();
  const [getSubdomains,{isLoading:subdomainLoading}] = useGetSubdomainsMutation();
  const [getQuestions,{isLoading:questionLoading}] = useGetQuestionsMutation();
  const [createTest,{isLoading:createTestLoading}] = useCreateTestMutation();
  const [getTestById,{isLoading:getTestLoading}] = useGetTestByIdMutation();
  const [updateTest,{isLoading:updateTestLoading}] = useUpdateTestMutation();

  const {id} = useParams();

  const [topics,setTopics] = useState([]);
  const [subtopics,setSubtopics] = useState([]);
  const [domains,setDomains] = useState([]);
   const [subdomains,setSubdomains] = useState([]);
   const [selectedQuestion,setSelectedQuestion] = useState(null)
   const [selectedIdx,setSelectedIdx] = useState(0)
   const [questions,setQuestions] = useState([]);
   const [selectedQuestions,setSelectedQuestions] = useState([]);
  const [firstRender,setFirstRender] = useState(false);
    const [isLoading,setIsLoading] = useState(false); 
    const [curPage, setCurPage] = useState(1);
    const [resultPerPage, setResultPerPage] = useState(10);
    const curPageHandler = (p) => setCurPage(p);
    const [searchInput, setSearchInput] = useState("");
    const [showQueModal, SetShowQueModal] = useState(false);
    const handleShowQueModal = ()=>SetShowQueModal(true);
    const handleHideQueModal = ()=>SetShowQueModal(false);

    const [showCancelModal,setShowCancelModal] = useState(false);
    const handleShowCancelModal = ()=>setShowCancelModal(true);
    const handleHideCancelModal = ()=>setShowCancelModal(false);

    const [showTestModal, SetShowTestModal] = useState(false);
    const handleShowTestModal = ()=>SetShowTestModal(true);
    const handleHideTestModal = ()=>SetShowTestModal(false);
    const [showCreatedModal,setShowCreatedModal] = useState(false);
    const handleShowCreatedModal = ()=>{
      setShowCreatedModal(true);
      setTimeout(()=>(
  handleHideCreatedModal()
      ),3000)
    }
  const [query, setQuery] = useState("");

  const navigate = useNavigate();
  const handleHideCreatedModal = ()=>{
    setShowCreatedModal(false);
    navigate(-1)
  }

  
  const [form,setForm] = useState({
     test_type: 'Quiz',
     number_of_questions: 50,
     duration_in_mins: 30,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
   
    setForm({ ...form, [name]: value });
  };

  
  const fetchTest = async()=>{
    try {
      setFirstRender(true);
      const data = await getTestById(id).unwrap();
      console.log(data);
        const {test} = data;
      console.log(test)
        setForm({
          test_name: test?.test_name,
          number_of_questions: test?.number_of_questions,
          duration_in_mins: test?.duration_in_mins,
          test_type: test?.test_type,
          subdomain: test?.subdomain_reference?._id,
          domain: test?.subdomain_reference?.domain_reference,
        })

        setSelectedQuestions(test?.questions_reference)
       setFirstRender(false);
    } catch (error) {
      getError(error)
    }
  }



  useEffect(()=>{
     console.log(selectedQuestions);
  },[selectedQuestions])
  useEffect(()=>{
     if(id){
      fetchTest();
     }
  },[id])


  useEffect(()=>{
   fetchDomains({getDomains,setDomains})
  },[])

  useEffect(()=>{
    setSubdomains([]);
     setTopics([]);
    setSubtopics([]);
    setQuestions([])

   if(form?.domain){

     fetchSubdomains({getSubdomains,setSubdomains,id:form?.domain})
   }
  },[form?.domain])

  useEffect(()=>{
   if(form?.subdomain){
    setTopics([]);
    setSubtopics([]);
    if(!firstRender){

      setSelectedQuestions([]);
    }
    
    setForm({ ...form, subtopic: 'all',topic:'all' });
     fetchTopics({getTopics,setTopics,id:form?.subdomain})
   }
  },[form?.subdomain])

  useEffect(()=>{
    setSubtopics([]);
    if(form?.topic === 'all'){
    setForm({ ...form, subtopic: 'all' });
  }
   if(form?.topic && form?.topic != 'all'){
     
     fetchSubtopics({getSubtopics,setSubtopics,id:form?.topic})
   }
  },[form?.topic])

  useEffect(()=>{
    if(form?.subtopic){
        // fetchQuestions({getQuestions,setQuestions,id:form?.subtopic,query})
        fetchQuestions({getQuestions,setQuestions,id:form?.subtopic !='all' && form?.topic !='all'?form?.subtopic:false ,topic:form?.subtopic === 'all' && form?.topic !='all'?form?.topic :false,subdomain:form?.topic==='all'?form?.subdomain:false,status:'Completed',query})

      }
},[form?.subtopic,form?.topic,form?.subdomain,query])


const handleCheckboxChange = (isChecked, question) => {
  if (isChecked) {
      if(form?.number_of_questions > selectedQuestions?.length){
          console.log(selectedQuestions.length)
          console.log(form.number_of_questions)

          setSelectedQuestions(prev => [...prev, question]);
      }else{
          toast.warn("Selected no of questions can't be greater than no of questions")
      }
  } else {
      setSelectedQuestions(prev => prev.filter(q => q?._id !== question?._id));
  }
};


const handleSelectAllCheckboxChange = (isChecked, questions) => {
  if (isChecked) {
      if(form.number_of_questions > selectedQuestions.length){
          console.log(selectedQuestions.length)
          console.log(form.number_of_questions)

          setSelectedQuestions(prev => [...prev, ...questions]);
      }else{
          toast.warn("Selected no of questions can't be greater than no of questions")
      }
  } else {
      const remainingQuestions = selectedQuestions?.filter(selected => 
          !questions.some(question => question._id === selected._id)
      );
      setSelectedQuestions(remainingQuestions);        }
};

console.log(form);



const handleSubmit = async({draft=false})=>{

  if(selectedQuestions.length >0){
    if(selectedQuestions.length <= form.number_of_questions ){

    if(form?.test_name){


   try {
    const ques = selectedQuestions.map(que => que?._id); 
    const subtopics = selectedQuestions.map(que => que?.sub_topic_reference?._id);
    const uniqueSubtopics = [...new Set(subtopics)];
    
    const topicReferences = selectedQuestions.map(question => question?.sub_topic_reference?.topic_reference);
    const uniqueTopics = [...new Set(topicReferences?.map(topic => topic?._id))];
    
      const data = id? await updateTest({id,data:{
        test_name: form?.test_name,
        number_of_questions: form?.number_of_questions,
        questions_reference: ques,
        duration_in_mins: form?.duration_in_mins,
        test_type: form?.test_type,
        subdomain_reference: form?.subdomain,
        subtopic_reference: uniqueSubtopics,
        topic_reference: uniqueTopics,
        draft
      }}).unwrap()
     :
      await createTest({
        test_name: form?.test_name,
        number_of_questions: form?.number_of_questions,
        questions_reference: ques,
        duration_in_mins: form?.duration_in_mins,
        test_type: form?.test_type,
        subdomain_reference: form?.subdomain ,
        subtopic_reference: uniqueSubtopics,
        topic_reference: uniqueTopics,
        draft
      }).unwrap();

      console.log(data);

      !draft && handleShowCreatedModal();

      draft && navigate(-1);
      draft && toast.success('Test saved as Draft')

   } catch (error) {
    getError(error)
   }
  }else{
    toast.warn('Test Name is required');
}
}else{
  toast.warn("Selected no of questions can't be greater than no of questions")

}

}else{
    toast.warn('Please select atleast one question to proceed');
}
}



//     const domains = [
//         {name:'Engineering'},
//         {name:'Medical'},
// ]
// const subDomains = [
//         {name:'Mechanical Engineering'},
//         {name:'Civil Engineering'},
// ]



const numOfPages = Math.ceil(questions?.length / resultPerPage);
const skip = resultPerPage * (curPage - 1);
console.log("no of Page", numOfPages, resultPerPage,questions?.length);

const column = [
  <div className='d-flex'>
                    <Form.Check
                     className='me-2'
                    type="checkbox"
                   checked = {questions?.every(question => 
                        selectedQuestions.some(selected => selected._id === question._id)
                    )}
                    onChange={(e) => handleSelectAllCheckboxChange(e.target.checked, questions)}
                /> Select </div>,
  "Question",
  "D.L",
  "Type",
  "Action"
];


  return (
    <MotionDiv>
        <Row>
        <Col sm={4}>
            <FormField
            label={"Search & Select Domain:"}
            type={"select"}
            name={'domain'}
            value={form?.domain}
            onChange={handleChange}
            options={domains?.map((item) => ({
              label: item?.domain_name,
              value: item?._id,
            }))}
          />
        </Col>
        <Col>
          <FormField
            label={"Search & Select Speciality Area:"}
            type={"select"}
            name={'subdomain'}
            disabled={!form?.domain}
            value={form?.subdomain}
            onChange={handleChange}
            options={subdomains?.map((item) => ({
              label: item?.sub_domain_name,
                value: item?._id,
            }))}
          />
        </Col>
        <Col>
          <FormField
            label={"Search & Select Topic:"}
            type={"select"}
            name={'topic'}
            disabled={!form?.subdomain}
            value={form?.topic}
            onChange={handleChange}
            options={[
              {value:'all',label:'All'},
              ...(topics?.length > 0 
                ? topics?.map((topic) => ({
                    value: topic?._id,
                    label: topic?.topic_name
                }))
                : []
          )
          ]}
          />
        </Col>
        </Row>
        <Row>
         
        <Col sm={4}>
        <FormField
            label={"Search & Select Subtopic:"}
            type={"select"}
            name={'subtopic'}
            disabled={!form?.topic}
            value={form?.subtopic}
            onChange={handleChange}
            // options={subtopics?.map((item) => ({
            //   label: item?.sub_topic_name,
            //   value: item?._id,
            // }))}
            options={[
              { value: 'all', label: 'All' },
              ...(subtopics?.length > 0 
                  ? subtopics?.map((subtopic) => ({
                      value: subtopic?._id,
                      label: subtopic?.sub_topic_name
                  }))
                  : []
              )
          ]}
          />
       
            </Col>
            <Col>
            <FormField
             type={'text'}
             label={'Enter Test Name:'}
             placeholder={'Test Name'}
             name={'test_name'}
             value={form?.test_name}
             onChange={handleChange}
            />
            
            </Col>
            <Col>
            <FormField
              label={"Type of Test:"}
              type={"select"}
              name={'test_type'}
              value={form?.test_type}
              onChange={handleChange}
              options={[
                { label: "Quiz", value: "Quiz" },
                { label: "Exam", value: "Exam" },
              ]}

            />
            
            </Col>
        </Row>
        <Row>
            <Col sm={4}>
                    <FormField
              label={"No. of Questions:"}
              type={"number"}
              name={'number_of_questions'}
              onChange={handleChange}
              min={1}
              value={form?.number_of_questions}
              // options={[{name:'25'},{name:'50'}].map((item) => ({
              //     label: item.name,
              //     value: item.name,
              //   }))}

            />
            
            </Col>
            <Col sm={4}>
                    <FormField
              label={"Time Alloted(in mins):"}
              type={"number"}
              name={'duration_in_mins'}
              onChange={handleChange}
              value={form?.duration_in_mins}
              min={5}
              // options={[{name:'30'},{name:'25'}].map((item) => ({
              //     label: `${item.name} minutes`,
              //     value: item.name,
              //   }))}
    
            />
            
            </Col>
        </Row>

        <CustomTable
          loading={questionLoading}
          column={column}
          rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
          // paging={numOfPages > 0}
          paging={false}
          pageProps={{ numOfPages, curPage }}
          pageHandler={curPageHandler}
           search={true}
           searchProps={{ searchInput, setSearchInput, setQuery }}
            isTitle ='true'
            title={<p className='pt-4' style={{fontSize:'1rem'}}>{`Selected Questions: ${selectedQuestions?.length}`}</p>}
        //   isCreateBtn={true}
        //   createBtnProps={{
        //     text: 'Prof',
        //     createURL: '/admin/profs/add-prof',
        //   }}
        >
          {questions &&
            questions?.map((data, i) => (
              <tr key={i} className="odd text-center" style={{fontSize:'0.75rem'}}>
                <td className='text-start'> 
                  <Form.Check
                     
                        type="checkbox"
                        checked={selectedQuestions?.some(question => question?._id === data?._id)}
                        onChange={(e) => handleCheckboxChange(e.target.checked, data)}
                    />
    </td>
    <td className='text-start' style={{maxWidth:'70%',textWrap:'wrap'}}>
                <CreateMarkup content={data?.question?.substring(0, 100)}/>
                </td>
                <td className='px-2'><div className='shadow border px-2 py-1'>{data?.difficulty_level}</div></td>
                <td className='px-2'><div className='shadow border px-2 py-1'>{
                data?.question_type =='Select Best Option'?'SBO'
                : data?.question_type =='True/False'?'T/F'
                : data?.question_type =='Multiple Choice Question'?'MCQ'
                :'N/A'}</div></td>
            
                <td>
                  <EditButton
                    onClick={() => navigate(`/admin/questions/edit-question/${data?._id}`)}
                  />
                  <ViewButton
                    onClick={()=>{
                      setSelectedIdx(i);
                      setSelectedQuestion(data);
                      handleShowQueModal();
                    
                    }}
                  />
                
          
                </td> 
              </tr>
            ))}
        </CustomTable>
        <Row className="my-3">
        <Col>
        <Button variant="transparent" className="del-btn" onClick={handleShowCancelModal}>Cancel</Button>
        </Col>
        <Col className="text-end">
        <Button variant="transparent" className="add-btn m-1" onClick={()=>handleSubmit({draft:true})}>Save as Draft
           {/* <FaRegSave/> */}
           </Button>
        <Button variant="transparent" className="blue-btn m-1" onClick={handleShowTestModal}>Preview</Button>
        </Col>
      </Row>
      <QuestionModal question={selectedQuestion} idx={selectedIdx} show={showQueModal} onHide={handleHideQueModal} />

       <ViewTestModal
        onConfirm={handleSubmit} 
        onCancel={handleHideTestModal} 
         show={showTestModal} 
         onHide={handleHideTestModal} 
         selectedQuestions={selectedQuestions}
         data={form}
         loading={createTestLoading}
         />       

       <ModalTemplate
  show={showCreatedModal}
  onHide={handleHideCreatedModal}
  src={'/icons/tick.png'}
  title={`You have successfully ${id?'updated':'created'} a new test.`}
 
/>

<ModalTemplate
 show={showCancelModal}
 onHide={handleHideCancelModal}
 src={'/icons/question-red.png'}
title={`Are you sure you want to cancel ${id?'editing':'creating'} test?`}
onConfirm={()=>{
  handleHideCancelModal();
  navigate(-1);

}}
onDiscard={handleHideCancelModal}
loading={isLoading}
/>

    </MotionDiv>
  )
}

export default CreateTest