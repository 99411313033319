import React, { useEffect, useState } from 'react'
import { Col, Container, Modal, Row } from 'react-bootstrap'
import FormField from '../../components/layout/FormField'
import SearchField from '../../components/layout/SearchField'
import CustomTable, { AddButton, DeleteButton, EditButton, ViewButton } from '../../components/layout/CustomTable'
import { MotionDiv } from '../../components'
import { FiEdit } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import QuestionModal from '../../components/modals/QuestionModal'
import { useDeleteQuestionByIdMutation, useGetDomainsMutation, useGetQuestionsMutation, useGetSubTopicsMutation, useGetSubdomainsMutation, useGetTopicsMutation } from '../../features/apiSlice'
import { fetchDomains, fetchQuestions, fetchSubdomains, fetchSubtopics, fetchTopics } from '../../utils/apis'
import { getError } from '../../utils/error'
import CreateMarkup from '../../components/layout/CreateMarkup'
import { selectOptions, setDomain, setSubdomain, setSubtopic, setTopic, unsetOptions, unsetSubtopic, unsetTopic } from '../../features/optionSlice'
import { useDispatch, useSelector } from 'react-redux'
import ModalTemplate from '../../components/modals/ModalTemplate'
import { toast } from 'react-toastify'


function Questions() {

  const [getQuestions,{isLoading}] = useGetQuestionsMutation();
  const [deleteQuestionById,{isLoading:deleteQueloading}] = useDeleteQuestionByIdMutation();
  const [getTopics,{isLoading:topicLoading}] = useGetTopicsMutation();
  const [getSubtopics,{isLoading:subtopicLoading}] = useGetSubTopicsMutation();
  const [getDomains,{isLoading:domainLoading}] = useGetDomainsMutation();
  const [getSubdomains,{isLoading:subdomainLoading}] = useGetSubdomainsMutation();

  const [topics,setTopics] = useState([]);
  const [subtopics,setSubtopics] = useState([]);
  const [domains,setDomains] = useState([]);
   const [subdomains,setSubdomains] = useState([]);
   const [questions,setQuestions] = useState([]);
  const [query,setQuery]=useState('');
  const {domain,topic,subtopic,subdomain} = useSelector(selectOptions);
  const dispatch = useDispatch();
    
    const [curPage, setCurPage] = useState(1);
    const [resultPerPage, setResultPerPage] = useState(10);
    const [showCancelModal,setShowCancelModal] = useState(false);
    const handleShowCancelModal = ()=>setShowCancelModal(true);
  const handleHideCancelModal = ()=>setShowCancelModal(false);
    const [showQueModal, SetShowQueModal] = useState(false);
    const [selectedQuestion,setSelectedQuestion] = useState(null)
    const [selectedIdx,setSelectedIdx] = useState(0)
    const handleShowQueModal = ()=>{
      SetShowQueModal(true)
    };
    const handleHideQueModal = ()=>SetShowQueModal(false);

    const [form,setForm] = useState({

    });

    const handleDeleteQuestion = async()=>{
      try {
        const data = await deleteQuestionById(selectedQuestion?._id).unwrap();
        toast.success(data?.message);
        handleHideCancelModal();
        fetchQuestions({getQuestions,setQuestions,id:subtopic,query})
    
      } catch (error) {
        getError(error)
      }
    }
   
  

    useEffect(()=>{

      if(!domain || !domains?.some((d)=>d?._id === domain)){

      
      if(domains?.length >0 ){
        dispatch( setDomain(domains[0]?._id))
      }
  }

    },[domains])

    useEffect(()=>{
 
      if(!subdomain || !subdomains?.some((sd)=>sd?._id === subdomain)){

  
   if(subdomains?.length >0 ){
 
     dispatch( setSubdomain(subdomains[0]?._id))
 
   }
  }
 
 },[subdomains])
    useEffect(()=>{
 
      if(!topic || !topics?.some((t)=>t?._id === topic)){

  
   if(topics?.length >0 ){
 
     dispatch( setTopic(topics[0]?._id))
 
   }
  }
 
 },[topics])
    useEffect(()=>{
 
      if((!subtopic || !subtopics?.some((st)=>st?._id === subtopic) ) && !subtopic === 'all'){

  
   if(subtopics?.length >0 ){
 
     dispatch( setSubtopic(subtopics[0]?._id))
 
   }
  }
 
 },[subtopics])


    useEffect(()=>{
     fetchDomains({getDomains,setDomains})
    },[])
 
    useEffect(()=>{
     if(domain){
 
       fetchSubdomains({getSubdomains,setSubdomains,id:domain})
     }
    },[domain])
 
    useEffect(()=>{
     if(subdomain){
 
       fetchTopics({getTopics,setTopics,id:subdomain})
     }
    },[subdomain])
 
    useEffect(()=>{
     if(topic){
 
       fetchSubtopics({getSubtopics,setSubtopics,id:topic})
     }
    },[topic])


   useEffect(()=>{
        if(subtopic){
            fetchQuestions({getQuestions,setQuestions,id: subtopic !='all'?subtopic:false ,topic: subtopic === 'all'?topic :false,query})
        }
   },[subtopic,query])




   


const navigate = useNavigate();

    const curPageHandler = (p) => setCurPage(p);

//     const domains = [
//         {name:'Engineering'},
//         {name:'Medical'},
// ]
// const subDomains = [
//         {name:'Mechanical Engineering'},
//         {name:'Civil Engineering'},
// ]


  

const numOfPages = Math.ceil(questions?.length / resultPerPage);
const skip = resultPerPage * (curPage - 1);
console.log("no of Page", numOfPages, resultPerPage,questions?.length);

const column = [
  "No.",
  "Question",
  "D.L",
  "Type",
  "Status",
  "Action"
];


  return (
    <MotionDiv>
   
        <Row>
        <Col sm={4}>
            <FormField
            label={"Search & Select Domain:"}
            type={"select"}
            name={'domain'}
            value={domain}
          
            onChange={(e)=>{
              dispatch(unsetOptions())
              dispatch(setDomain(e.target.value))
      
          }}
            options={domains?.map((item) => ({
              label: item?.domain_name,
              value: item?._id,
            }))}
          />
        </Col>
        <Col>
          <FormField
            label={"Search & Select Speciality Area:"}
            type={"select"}
            name={'subdomain'}
            // disabled={!domain}
            value={subdomain}
            onChange={(e)=>{
              dispatch(unsetTopic())
              dispatch(unsetSubtopic())
              dispatch(setSubdomain(e.target.value))
      
          }}            
            options={subdomains?.map((item) => ({
              label: item?.sub_domain_name,
                value: item?._id,
            }))}
          />
        </Col>
        <Col>
          <FormField
            label={"Search & Select Topic:"}
            type={"select"}
            name={'topic'}
            // disabled={!form?.subdomain}
            value={topic}
            onChange={(e)=>{
              dispatch(unsetSubtopic())
              dispatch(setTopic(e.target.value))
      
          }}   
              options={topics?.map((item) => ({
              label: item?.topic_name,
              value: item?._id,
            }))}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
        <FormField
            label={"Search & Select Subtopic:"}
            type={"select"}
            name={'subtopic'}
            // disabled={!form?.topic}
            value={subtopic}
            onChange={(e)=>{     
              dispatch(setSubtopic(e.target.value))
          }}       
          options={[
            { value: 'all', label: 'All' },
            ...(subtopics?.length > 0 
                ? subtopics?.map((subtopic) => ({
                    value: subtopic?._id,
                    label: subtopic?.sub_topic_name
                }))
                : []
            )
        ]}
          />
       
            </Col>
            <Col sm={4} className='d-flex align-items-center pt-3'> 
            <SearchField placeholder='Search Question' query={query} setQuery={setQuery} />
             </Col>
             <Col className=' d-flex align-items-center justify-content-end pt-3' sm={4}>   
            <AddButton title={'Add New Question'} url='/admin/questions/add-question'/>
            </Col>
        </Row>

        <CustomTable
          loading={isLoading}
          column={column}
          rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
          // paging={numOfPages > 0}
          paging={false}
          pageProps={{ numOfPages, curPage }}
          pageHandler={curPageHandler}
          //  search={true}
        //    searchProps={{ searchInput, setSearchInput, setQuery }}
          isTitle="true"
          title="Questions List"
        //   isCreateBtn={true}
        //   createBtnProps={{
        //     text: 'Prof',
        //     createURL: '/admin/profs/add-prof',
        //   }}
        >
          {questions &&
            questions?.map((data, i) => (
              <tr key={i} className="odd" style={{fontSize:'0.75rem'}}>
                <td className="text-center">{skip + i + 1}</td>
                <td className='text-start' style={{width:'65%',textWrap:'wrap',wordBreak:'break-word',verticalAlign:'middle'}}>
                <CreateMarkup content={data?.question?.substring(0, 200)}/>
                </td>
                <td className='text-center'><div className='shadow px-2' >{data?.difficulty_level}</div></td>
                <td className='text-center'><div className='shadow px-2'>
                {data?.question_type =='Select Best Option'?'SBO'
                : data?.question_type =='True/False'?'T/F'
                :  data?.question_type =='Multiple Choice Question'?'MCQ'
                :'N/A'}</div>
                </td>
                <td className='text-center'><div className='rounded-pill p-1' style={{background:`${data?.status ==='Completed'? 'rgba(0, 200, 56, 0.1)':'rgba(255, 156, 7, 0.1)'} `,color:`${data?.status ==='Completed'? 'rgba(0, 200, 56, 1)':'rgba(255, 156, 7, 1)'} ` }}>{data?.status}</div></td>
             
                {/* <td>{user.role}</td> */} 
                <td className='text-center'>
                  <EditButton
                    onClick={() => navigate(`/admin/questions/edit-question/${data?._id}`)}
                  />
                  <ViewButton
                    onClick={()=>{
                      setSelectedIdx(i);
                      setSelectedQuestion(data);
                      handleShowQueModal();
                    
                    }}
                  />
                  <DeleteButton onClick={() =>{
                    //  deleteUser(user?._id)
                    setSelectedQuestion(data);
                    handleShowCancelModal();
                     console.log('deleted')
                  }}
           />
                </td> 
              </tr>
            ))}
        </CustomTable>

<QuestionModal question={selectedQuestion} idx={selectedIdx} show={showQueModal} onHide={handleHideQueModal} />
<ModalTemplate
    title={"Are you sure you want to delete this question?"}
    src={'/icons/deleteIcon.png'}
    onDiscard={handleHideCancelModal}
    show={showCancelModal}
    onHide={handleHideCancelModal}
    onConfirm={handleDeleteQuestion}
    loading={deleteQueloading}
    deleteBtn={true}
/>
    </MotionDiv>
  )
}

export default Questions