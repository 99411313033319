import React, { useEffect, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import FormField from '../../components/layout/FormField'
import SearchField from '../../components/layout/SearchField'
import CustomTable, { AddButton, DeleteButton, EditButton, ViewButton } from '../../components/layout/CustomTable'
import { MotionDiv } from '../../components'
import ViewTestModal from '../../components/modals/ViewTestModal'
import {useNavigate} from 'react-router-dom'
import ModalTemplate from '../../components/modals/ModalTemplate'
import { fetchDomains, fetchSubdomains, fetchSubtopics, fetchTopics } from '../../utils/apis'
import { useDeleteTestByIdMutation, useGetDomainsMutation, useGetSubTopicsMutation, useGetSubdomainsMutation, useGetTestsMutation, useGetTopicsMutation, useUpdateTestMutation } from '../../features/apiSlice'
import { getError } from '../../utils/error'
import { useDispatch, useSelector } from 'react-redux'
import { selectOptions, setDomain, setSubdomain, unsetOptions, unsetSubtopic, unsetTopic } from '../../features/optionSlice'
import { toast } from 'react-toastify'


function Tests() {

    const [isLoading,setIsLoading] = useState(false);
    const [curPage, setCurPage] = useState(1);
    const [resultPerPage, setResultPerPage] = useState(10);
    const curPageHandler = (p) => setCurPage(p);
    const [getTests,{isLoading:testLoading}] = useGetTestsMutation();
    const [updateTest,{isLoading:updateTestLoading}] = useUpdateTestMutation();
    const [deleteTestbyId,{isLoading:deleteTestLoading}] = useDeleteTestByIdMutation();
    const [getDomains,{isLoading:domainLoading}] = useGetDomainsMutation();
    const [getSubdomains,{isLoading:subdomainLoading}] = useGetSubdomainsMutation();
  
    const navigate = useNavigate();
    const dispatch = useDispatch();
     const {domain,subdomain} = useSelector(selectOptions)

    const [domains,setDomains] = useState([]);
     const [subdomains,setSubdomains] = useState([]);
     const [form,setForm] = useState({});
     const [tests,setTests] = useState([]);
     const [query,setQuery]=useState('');
     const [selectedTest,setSelectedTest] = useState(null)
    const [showTestModal, SetShowTestModal] = useState(false);
    const handleShowTestModal = ()=>SetShowTestModal(true);
    const handleHideTestModal = ()=>SetShowTestModal(false);
    const [showCancelModal,setShowCancelModal] = useState(false);
    const handleShowCancelModal = ()=>setShowCancelModal(true);
  const handleHideCancelModal = ()=>setShowCancelModal(false);
    const [showDraftModal,setShowDraftModal] = useState(false);
    const handleShowDraftModal = ()=>setShowDraftModal(true);
  const handleHideDraftModal = ()=>setShowDraftModal(false);
//     const domains = [
//         {name:'Engineering'},
//         {name:'Medical'},
// ]
// const subDomains = [
//         {name:'Mechanical Engineering'},
//         {name:'Civil Engineering'},
// ]

const handleDeleteTest = async()=>{
  try {
    const data = await deleteTestbyId(selectedTest?._id).unwrap();
    toast.success(data?.message);
    handleHideCancelModal();
    fetchTests();

  } catch (error) {
    getError(error)
  }
}

const handleChange = (e) => {
  const { name, value } = e.target;
 
  setForm({ ...form, [name]: value });
};


useEffect(()=>{
 fetchDomains({getDomains,setDomains})
},[])

useEffect(()=>{
 if(domain){

   fetchSubdomains({getSubdomains,setSubdomains,id:domain})
 }
},[domain])

useEffect(()=>{
 if(subdomain){

   fetchTests()
 }
},[subdomain,query])

useEffect(()=>{

  if(!domain || !domains?.some((d)=>d?._id === domain)){

  
  if(domains?.length >0 ){
    dispatch( setDomain(domains[0]?._id))
  }
}

},[domains])

useEffect(()=>{

  if(!subdomain || !subdomains?.some((sd)=>sd?._id === subdomain)){


if(subdomains?.length >0 ){

 dispatch( setSubdomain(subdomains[0]?._id))

}
}

},[subdomains])


const handleUpdateTest = async({id,status})=>{
  try {
    const data = await updateTest({id,data:{
      status: status,
    }})

    console.log(data);
    fetchTests();

  } catch (error) {
    getError(error)
  }
}

const fetchTests = async()=>{
  try {
    const data = await getTests({id:subdomain,params:`key=${query}`}).unwrap();

    console.log(data);
    setTests(data?.tests);


  } catch (error) {
    getError(error)
  }
}

// useEffect(()=>{
//  if(form?.topic){

//    fetchSubtopics({getSubtopics,setSubtopics,id:form?.topic})
//  }
// },[form?.topic])


const handleStatusToggler=({id,status})=>{
  try {
     handleUpdateTest({id,status: status === 'Active' ? 'Inactive' : 'Active'});
  } catch (error) {
    getError(error);
  }
}




const numOfPages = Math.ceil(tests?.length / resultPerPage);
const skip = resultPerPage * (curPage - 1);
console.log("no of Page", numOfPages, resultPerPage,tests?.length);

const column = [
  "No.",
  "Test Name",
  "Type",
  "No. of Questions",
  "Time",
  "Created On",
  "Status",
  "Action"
];



  return (
    <MotionDiv>
        <Row>
        <Col sm={4}>
            <FormField
            label={"Search & Select Domain:"}
            type={"select"}
            name={'domain'}
            value={domain}
            onChange={(e)=>{
              dispatch(unsetOptions())
              dispatch(setDomain(e.target.value))
      
          }}
            options={domains?.map((item) => ({
              label: item?.domain_name,
              value: item?._id,
            }))}
          />
        </Col>
        <Col sm={4}>
          <FormField
            label={"Search & Select Speciality Area:"}
            type={"select"}
            name={'subdomain'}
            disabled={!domain}
            value={subdomain}
            onChange={(e)=>{
              dispatch(unsetTopic())
              dispatch(unsetSubtopic())
              dispatch(setSubdomain(e.target.value))
      
          }}    
            options={subdomains?.map((item) => ({
              label: item?.sub_domain_name,
                value: item?._id,
            }))}
          />
        </Col>
        {/* <Col>
          <FormField
            label={"Search & Select Topic:"}
            type={"select"}
            name={'topic'}
            disabled={!form?.subdomain}
            value={form?.topic}
            onChange={handleChange}
            options={topics?.map((item) => ({
              label: item?.topic_name,
                value: item?._id,
            }))}
          />
        </Col> */}
        </Row>
        <Row>
        {/* <Col sm={4}>
         
        <FormField
            label={"Search & Select Subtopic:"}
            type={"select"}
            name={'subtopic'}
            disabled={!form?.topic}
            value={form?.subtopic}
            onChange={handleChange}
            options={subtopics?.map((item) => ({
              label: item?.sub_topic_name,
              value: item?._id,
            }))}
          />
         </Col> */}
            {/* <Col sm={4} className='d-flex align-items-center pt-3'><SearchField placeholder='Search For Test'/></Col> */}
            {/* <Col sm={4} className='d-flex align-items-center justify-content-end pt-3'><AddButton title={'Create New Test'} url='/admin/tests/create-test'/></Col> */}
        </Row>

        <CustomTable
          loading={testLoading}
          column={column}
          rowNo={resultPerPage}
          rowProps={{ setResultPerPage }}
          // paging={numOfPages > 0}
          paging={false}
          pageProps={{ numOfPages, curPage }}
          pageHandler={curPageHandler}
           search={true}
           searchProps={{ query, setQuery }}
          isTitle="true"
          title="Test Details"
          isCreateBtn={true}
          createBtnProps={{
            text: 'Create New Test',
            createURL: '/admin/tests/create-test',
          }}
        >
          {tests &&
            tests?.map((data, i) => (
              <tr key={data?._id} className="odd text-center">
                <td className="">{skip + i + 1}</td>
                <td>{data?.test_name?.substring(0,50)}</td>
                <td>{data?.test_type}</td>
                <td className='d-flex justify-content-center'><div  className='rounded-pill p-1 px-3' style={{backgroundColor:`${data?.questions_reference?.length === data?.number_of_questions?'rgba(18, 221, 0, 0.1)':'rgba(255, 170, 6, 0.1)'}`}}><span style={{color:`${data?.questions_reference?.length === data?.number_of_questions?'rgba(18, 221, 0, 1)':'rgba(255, 170, 6, 1)'}`}}>{data?.questions_reference?.length}</span>/{data?.number_of_questions}</div></td>
                <td>{data?.duration_in_mins} min</td>
                <td>{new Date(data?.createdAt).toLocaleDateString('en-GB')}</td>                {/* <td>{data?.status}</td> */}
                <td>  <Form.Check
      type="switch"
      id="custom-switch"
      className=" test-toggle-switch"
      
      checked={data?.status === 'Active'?true:false}
      onChange={()=>{
        if(data?.questions_reference?.length === data?.number_of_questions){

          handleStatusToggler({id:data?._id,status: data?.status})
        }else{
            setSelectedTest(data)
            handleShowDraftModal();
        }
      
      }}
    /></td>
             
                {/* <td>{user.role}</td> */} 
                <td>
                  <EditButton
                    onClick={() => navigate(`/admin/tests/edit-test/${data?._id}`)}
                    disabled={data?.status === 'Active'}
                  />
                  <ViewButton
                    onClick={()=>{
                      setSelectedTest(data)
                      handleShowTestModal()
                    }}
                  />
                  <DeleteButton onClick={()=>{
                    setSelectedTest(data);
                    handleShowCancelModal();
                  }}
           />
                </td> 

              </tr>
            ))}
        </CustomTable>

        <ViewTestModal data={selectedTest} selectedQuestions={selectedTest?.questions_reference} show={showTestModal} onHide={handleHideTestModal} />       
       
        <ModalTemplate
    title={"To make this test active you have to work on draft"}
    src={'/icons/question-green.png'}
    onDiscard={handleHideDraftModal}
    show={showDraftModal}
    onHide={handleHideDraftModal}
    onConfirm={()=>navigate(`/admin/tests/edit-test/${selectedTest?._id}`)}
    buttonConfirmTxt={'View Draft'}
/>
        <ModalTemplate
    title={"Are you sure you want to delete this test?"}
    src={'/icons/question-red.png'}
    onDiscard={handleHideCancelModal}
    show={showCancelModal}
    onHide={handleHideCancelModal}
    onConfirm={handleDeleteTest}
    loading={deleteTestLoading}
    deleteBtn={true}
/>
      
    </MotionDiv>
  )
}

export default Tests